<template>
  <b-card>
    <!-- START: Action row -->
     <div style="margin-bottom: 12px;">
      <ImexAvailableExportsDropdown v-if="fileExportList.length > 0" :list="fileExportList"/>
    </div>
    <div class="mb-2 d-flex table-actions">
      <prozess-search-input
        v-model="query"
        :placeholder="$t('Search')"
      />
      <b-button
        id="create-button"
        variant="primary"
        class="ml-1"
        :disabled="onExport"
        :class="{
          'icon-disabled': !hasSystemSettingsActions || onExport,
        }"
        @click="openModal()"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Create New') }}</span>
      </b-button>
      <b-tooltip target="create-button">
        {{ hasSystemSettingsActions ? $t('Create') : $t('no-action-permission') }}
      </b-tooltip>

      <b-button
        id="create-button"
        variant="primary"
        class="ml-1"
        :disabled="onExport"
        @click="exportClicked()"
      >
        <span class="align-middle">{{ $t('Export') }}</span>
      </b-button>

    </div>
    <!-- END: Action row -->
    <!-- START: Table -->
    <b-table
      ref="refTable"
      :busy="loading"
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t('No matching records found')"
      class="table-responsive"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #head()="{ label }">
        <div class="w-100 text-center">
          <span class="text-nowrap">{{ $t(label) }}</span>
        </div>
      </template>
      <template #cell(name)="{ item }">
        <div style="max-width: 240px">
          <span>{{ item.name }} </span>
        </div>
      </template>
      <template #cell(type)="{ item }">
        <div class="w-100 text-center">
          <span class="text-nowrap"> {{ $t(item.type === 'PERCENTAGE' ? 'Percentage Based' : 'Amount Based') }}</span>
        </div>
      </template>
      <template #cell(agreementType)="{ item }">
        <div class="w-100 text-center">
          <span class="text-nowrap">{{ item.agreementType }}</span>
        </div>
      </template>

      <!-- START: Actions Column -->
      <template #cell(manage)="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <!-- Clone Button -->
          <feather-icon
            :id="`tooltip-${item.uuidKey}-clone`"
            class="mx-1 cursor-pointer"
            icon="CopyIcon"
            size="16"
            @click="cloneModel(item)"
          />
          <b-tooltip
            :target="`tooltip-${item.uuidKey}-clone`"
            :title="$t('Clone')"
            placement="top"
          />
          <!-- Edit Button -->
          <feather-icon
            :id="`tooltip-${item.uuidKey}-edit`"
            class="mx-1 cursor-pointer"
            icon="EditIcon"
            size="16"
            @click="openModal(item.uuidKey)"
          />
          <b-tooltip
            :target="`tooltip-${item.uuidKey}-edit`"
            :title="$t('Edit')"
            placement="top"
          />
          <!-- Delete Button -->
          <feather-icon
            :id="`tooltip-${item.uuidKey}-delete`"
            size="16"
            icon="TrashIcon"
            class="mx-1 cursor-pointer"
            @click="remove(item.uuidKey)"
          />
          <b-tooltip
            :target="`tooltip-${item.uuidKey}-delete`"
            :title="$t('Delete')"
            placement="top"
          />
        </div>
      </template>
      <!-- END: Action Column -->
    </b-table>
    <!-- END: Table -->
    <b-pagination
      v-model="page"
      :total-rows="allItemsCount"
      :per-page="size"
      first-number
      align="end"
      last-number
      class="mt-1 mt-sm-0 align-self-end"
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon
          icon="ChevronLeftIcon"
          size="20"
        />
      </template>
      <template #next-text>
        <feather-icon
          icon="ChevronRightIcon"
          size="20"
        />
      </template>
    </b-pagination>
    <!-- modal scrolling content -->
    <AddModelModal
      ref="editModel"
      @submit="save"
    />
  </b-card>
</template>

<script>
import modelsService from '@/services/models'
import imex from '@/services/imex'
import { BButton, BForm, BFormInput, BFormGroup, BCardText } from 'bootstrap-vue'
import AddModelModal from './AddModelModal.vue'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'
import ImexAvailableExportsDropdown from '@/views/shared/Imex/ImexAvailableExportsDropdown.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debounce } from 'lodash'
import listMixinFactory from '@/mixins/list'

export default {
  name: 'CommissionModels',
  components: {
    AddModelModal,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCardText,
    ToastificationContent,
    ImexActionsDropdown,
    ImexAvailableExportsDropdown,
  },
  data() {
    return {
      loading: false,
      items: [],
      fileExportList: [],
      categories: [],
      page: 1,
      size: 50,
      query: '',
      onExport: false,
      allItemsCount: 0,
      filteredItemsCount: 0,
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'category', label: 'Category', sortable: true },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'year', label: 'Year', sortable: true },
        { key: 'agreementType', label: 'Agreement Type', sortable: true },
        { key: 'manage', label: 'Manage' },
      ],
    }
  },
  watch: {
    page: {
      handler(oldPage, newPage) {
        this.get()
      },
      immediate: true,
    },
    query: {
      handler(oldQuery, newQuery) {
        this.doSearch()
      },
      immediate: true,
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    async get() {
      this.loading = true

      try {
        const res = await modelsService.getModels({
          page: this.page - 1,
          size: this.size,
          q: this.query,
        })

        if (!res.data) return

        const catRes = await modelsService.getCategories()
        this.categories = catRes.data.pageItems
        this.items = res.data.pageItems

        this.items.forEach(i => {
          const catMeta = this.categories.filter(c => c.metaStatusId === i.categoryId)[0]
          i.category = catMeta != null ? catMeta.metaStatusName : ''
        })

        this.allItemsCount = res.data.allItemsCount

        const exportListRes = await imex.getRecentExports(
          {
            schemaName: 'crm',
            entityType: 'model',
          })
       this.fileExportList = exportListRes.data

        this.loading = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    doSearch: debounce(function () {
      this.get()
    }, 500),
    save(c) {
      this.get()
    },
    select(m) {
      this.$router.push({ name: 'commission-models', params: { id: m.uuidKey } })
    },
    async remove(id) {
      if (this.$can('Delete', 'CRM') === false) return

      const res = await this.$swal({
        title: this.$t('Warning'),
        text: this.$t('Are you sure you want to remove?'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      })

      if (!res.value) {
        this.loading = false
        return
      }

      try {
        await modelsService.deleteModel(id)
        this.get()
      } catch (error) {
        console.log(error.toJSON())
        this.$toast.error(error.message)
      }
    },
    async exportClicked() {
      if (this.onExport) {
        return
      }
      this.onExport = true
       await modelsService.export().catch(e => {
        this.onExport = false
       })
       this.onExport = false
    },
    openModal(id) {
      this.$refs.editModel.openModal({
        open: true,
        id,
      })
    },
    async cloneModel(model) {
      this.loading = true
      const res = await modelsService.getModel(model.uuidKey)

      if (!res.data) return
      const baseModel = res.data
      const newModelPayload = {
        name: `${baseModel.name} (Klone)`,
        type: baseModel.type,
        agreementType: baseModel.agreementType,
        categoryId: baseModel.categoryId,
      }
      try {
        const newModelRes = await modelsService.addModel(newModelPayload)
        baseModel.products.forEach(async (pr, idx) => {
            const newProductPayload = {
              modelId: newModelRes.data.id,
              modelProductUuid: pr.modelProductUuid,
              productCondition: null,
              levelCondition: null,
              mirrorProduct: false,
              modelLevels: [],
            }
            const newProductRes = await modelsService.addProduct(newProductPayload)
            pr.modelLevels.slice().forEach(async mL => {
              mL.modelId = newModelRes.data.id
              mL.productId = newProductRes.data.id
              const newLevelRes = await modelsService.addLevel(mL)
            })
        })
        this.get()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Model Cloned'),
            text: 'Model succesfully cloned.',
            icon: 'CheckIcon',
            variant: 'primary',
          },
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
